define("cms-frontend/services/cookie-banner", ["exports", "@clark/cms-ui/services/cookie-banner"], function (_exports, _cookieBanner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _cookieBanner.default;
    }
  });
});