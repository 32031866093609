define("cms-frontend/components/wrapper-components/lead-gen-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZFabl7hH",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"button-wrapper\"],[[\"from\"],[\"cms-frontend/components/wrapper-components/lead-gen-button/styles\"]]]]]],[12],[2,\"\\n  \"],[8,\"lead-gen-cta\",[],[[\"@apperance\",\"@text\"],[[32,1,[\"appearance\"]],[32,1,[\"text\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/lead-gen-button/template.hbs"
    }
  });

  _exports.default = _default;
});