define("cms-frontend/components/lead-gen-cta/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Ay//vpr",
    "block": "{\"symbols\":[\"@appearance\",\"&attrs\",\"@text\"],\"statements\":[[8,\"button\",[[16,0,[31,[[30,[36,0],[\"lead-gen\"],[[\"from\"],[\"cms-frontend/components/lead-gen-cta/styles\"]]]]]],[17,2]],[[\"@appearance\",\"@url\",\"@onClick\"],[[30,[36,1],[[32,1],\"primary\"],null],\"#lead-gen\",[30,[36,3],[[30,[36,2],[[32,0,[\"modal\",\"leadGen\"]]],null],true],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,3]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"or\",\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/lead-gen-cta/template.hbs"
    }
  });

  _exports.default = _default;
});