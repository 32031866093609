define("cms-frontend/helpers/is-self-managed-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // NOTE: Here's a temporary list of the "new" (aka "non-legacy") components
  // to perform checks against. The "new" components are responsible for their
  // own layout and can be rendered directly, without any wrappers.
  // We need to preserve the wrappers and special handling for all the legacy
  // components in the meantime, though. Eventually, all the components will become
  // "new" and we can get rid of this helper then.
  //
  // The naming convention for the components used with the {{component}} helper
  // in the template is kebab-case, so we're following it here to avoid redundant
  // case transformations.
  //
  // Please note that this is for "wrapper-components" only as it's the only layer
  // that applies additional wrappers. Components inside `cms-ui` package aren't
  // concerned about how they're used.
  const selfManagedLayoutComponents = new Set(['accordion', 'article-summary', 'breadcrumbs', 'carousel', 'centered-content', 'contact-box', 'crosslink', 'faq', 'greenhouse', 'hero', 'hero-small', 'highlight', 'lead-gen', 'media-box', 'next-steps', 'picture-frame', 'quick-selection', 'rich-text', 'seo-hero', 'textbox', 'three-column', 'trust-seals', 'trustpilot', 'two-column', 'expert-tip']);

  var _default = Ember.Helper.helper(function (parameters) {
    const [name] = parameters;
    if (!name) return '';
    return selfManagedLayoutComponents.has(name);
  });

  _exports.default = _default;
});