define("cms-frontend/content/route", ["exports", "cms-frontend/utils/serialization", "cms-frontend/utils/url-utils"], function (_exports, _serialization, _urlUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContentRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = (_temp = class ContentRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fastboot", _descriptor, this);

      _initializerDefineProperty(this, "contentful", _descriptor2, this);

      _initializerDefineProperty(this, "meta", _descriptor3, this);

      _defineProperty(this, "headTags", void 0);
    }

    async model(_, transition) {
      const path = this.fastboot.isFastBoot ? this.fastboot.request.path : transition.intent.url;
      const slug = (0, _urlUtils.extractSlugFromPath)(path);
      if (!slug) return;
      const {
        shoebox
      } = this.fastboot;
      const shoeboxModelName = (0, _urlUtils.extractShoeboxFromSlug)(slug);
      let entry = shoebox.retrieve(shoeboxModelName);

      if (!entry) {
        entry = await this.contentful.getEntryBySlug(slug);
        if (!entry) return;

        if (this.fastboot.isFastBoot) {
          shoebox.put(shoeboxModelName, entry);
        }
      }

      this.headTags = this.meta.createHeadTags(entry.fields, slug);
      const {
        modals,
        layoutComponents,
        headerCtaButton
      } = entry.fields;
      const leadGenData = (0, _serialization.deepDeserializeModel)(layoutComponents.find(lc => lc.fields.componentType === 'leadGen'));
      const headerCtaButtonData = (0, _serialization.deepDeserializeModel)(headerCtaButton);
      return {
        modules: layoutComponents.map(_serialization.deserializeModel),
        modals: modals && modals.map(_serialization.deserializeModel) || undefined,
        leadGenData,
        headerCtaButtonData
      };
    }

    afterModel(model) {
      if (!model) {
        this.transitionTo('404');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "contentful", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "meta", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ContentRoute;
});