define("cms-frontend/services/tracking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TrackingService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class TrackingService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "fastboot", _descriptor2, this);

      _defineProperty(this, "didInsertTags", false);
    }

    init() {
      super.init();
      this.load();
    }

    async load() {
      if (this.fastboot.isFastBoot) {
        return;
      }

      const {
        default: ahoy
      } = await emberAutoImportDynamic("ahoy.js");
      ahoy.configure({
        visitsUrl: '/argos/visits',
        eventsUrl: '/argos/events'
      });

      if (this.api.token) {
        this.updateCSRFMetaTags();
      }
    }

    updateCSRFMetaTags() {
      this.didInsertTags = true;
      this.setMetaTag('csrf-token', this.api.token);
      this.setMetaTag('csrf-param', 'authenticity_token');
    }

    async track(eventName, eventProperties) {
      if (this.fastboot.isFastBoot) {
        return;
      }

      const properties = { ...eventProperties,
        // add some params to the event object we would like with all events
        appName: 'Website',
        pageTitle: document.title,
        pathName: document.location.href
      };

      if (this.didInsertTags) {
        this.updateCSRFMetaTags();
      }

      const {
        default: ahoy
      } = await emberAutoImportDynamic("ahoy.js");
      ahoy.track(eventName, properties);
      return true;
    } // TODO: Check with @Amerrnath and see if track service from
    // @clark-bi/tracking should be used


    trackWithGTM(eventName, eventProperties) {
      if (this.fastboot.isFastBoot || !window.dataLayer) {
        return;
      }

      const eventData = {
        event: eventName,
        appName: 'Website',
        pageTitle: document.title,
        pageURL: document.location.href,
        pathName: document.location.pathname,
        ...eventProperties
      };
      window.dataLayer.push(eventData);
    }
    /*
      Creates a meta tag with the specified `name` and `content` attributes or
      updates an existing meta tag with a matching name.
    */


    setMetaTag(name, content) {
      let meta = document.querySelector(`meta[name="${name}"]`);

      if (!meta) {
        meta = document.createElement('meta');
        meta.setAttribute('name', name);
      }

      meta.setAttribute('content', content);

      if (!meta.parentElement) {
        document.head.appendChild(meta);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TrackingService;
});