define("cms-frontend/helpers/optimized-image-url", ["exports", "@clark/cms-ui/helpers/optimized-image-url"], function (_exports, _optimizedImageUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _optimizedImageUrl.default;
    }
  });
  Object.defineProperty(_exports, "optimizedImageUrl", {
    enumerable: true,
    get: function () {
      return _optimizedImageUrl.optimizedImageUrl;
    }
  });
});