define("cms-frontend/components/preview/not-found-screen/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "c-title": "rgba(32, 49, 81, 1)",
    "not-found-screen": "_not-found-screen_1kuz7e",
    "layout-container": "_layout-container_1kuz7e",
    "message": "_message_1kuz7e"
  };
  _exports.default = _default;
});