define("cms-frontend/components/modals/lead-gen/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "danger-text": "#D51E50",
    "lead-gen-button": "_lead-gen-button_dvax5g",
    "lead-gen-checkbox": "_lead-gen-checkbox_dvax5g",
    "modal-footer": "_modal-footer_dvax5g",
    "error-message": "_error-message_dvax5g"
  };
  _exports.default = _default;
});