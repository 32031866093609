define("cms-frontend/components/wrapper-components/menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m89veFyG",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[8,\"cms-header\",[],[[\"@navigation\"],[[32,1,[\"menuItem\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/menu/template.hbs"
    }
  });

  _exports.default = _default;
});