define("cms-frontend/components/wrapper-components/responsive-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ch8mjtpw",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[8,\"responsive-image\",[],[[\"@defaultImage\",\"@defaultImageMaxWidth\",\"@desktopImage\",\"@desktopImageMaxWidth\"],[[30,[36,1],[[32,1,[\"defaultImage\"]],[30,[36,0],[[32,1,[\"defaultImage\"]]],null]],null],[32,1,[\"defaultImageMaxWidth\"]],[30,[36,1],[[32,1,[\"desktopImage\"]],[30,[36,0],[[32,1,[\"desktopImage\"]]],null]],null],[32,1,[\"desktopImageMaxWidth\"]]]],null]],\"hasEval\":false,\"upvars\":[\"transform-contentful-media\",\"if\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/responsive-image/template.hbs"
    }
  });

  _exports.default = _default;
});