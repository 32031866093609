define("cms-frontend/helpers/meta-style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (parameters) {
    const [styleJSON] = parameters;

    if (styleJSON) {
      return Object.keys(styleJSON).map(i => `${i}: ${styleJSON[i]}`).join(';');
    }

    return null;
  });

  _exports.default = _default;
});