define("cms-frontend/components/wrapper-components/greenhouse/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mwgBCNFB",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[8,\"greenhouse-board\",[],[[\"@boardToken\"],[[32,1,[\"boardToken\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/greenhouse/template.hbs"
    }
  });

  _exports.default = _default;
});