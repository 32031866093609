define("cms-frontend/components/wrapper-components/app-store/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "15tdfW6v",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"variant\"]],\"badge\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"common/app-store\",[],[[\"@appStoreLink\",\"@playStoreLink\"],[[32,1,[\"appStoreLink\"]],[32,1,[\"playStoreLink\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"common/app-store/banner\",[],[[\"@appStoreLink\",\"@playStoreLink\",\"@appStoreRating\",\"@playStoreRating\"],[[32,1,[\"appStoreLink\"]],[32,1,[\"playStoreLink\"]],[32,1,[\"appStoreRating\"]],[32,1,[\"playStoreRating\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/app-store/template.hbs"
    }
  });

  _exports.default = _default;
});