define("cms-frontend/utils/structured-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getStructuredData;

  function getStructuredData(structuredData) {
    let data = '';

    try {
      data = JSON.stringify(structuredData);
      return data;
    } catch {
      return '';
    }
  }
});