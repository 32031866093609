define("cms-frontend/components/wrapper-components/article-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yCVJpwJG",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[8,\"article-summary\",[],[[\"@title\",\"@description\",\"@summaryItems\"],[[32,1,[\"title\"]],[30,[36,0],[[32,1,[\"description\"]]],null],[30,[36,2],[[32,1,[\"summaryItems\"]],[30,[36,1],[[32,1,[\"summaryItems\"]]],null]],null]]],null]],\"hasEval\":false,\"upvars\":[\"transform-contentful-rich-text\",\"transform-contentful\",\"if\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/article-summary/template.hbs"
    }
  });

  _exports.default = _default;
});