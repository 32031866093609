define("cms-frontend/components/modals/lead-gen/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "cms-frontend/utils/constants", "cms-frontend/utils/serialization"], function (_exports, _component, _emberConcurrencyDecorators, _constants, _serialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LeadGenModal = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class LeadGenModal extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "modal", _descriptor, this);

      _initializerDefineProperty(this, "api", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "tracking", _descriptor4, this);

      _initializerDefineProperty(this, "lead", _descriptor5, this);

      _initializerDefineProperty(this, "serverError", _descriptor6, this);

      _initializerDefineProperty(this, "termsAccepted", _descriptor7, this);

      this.clearForm();
    }

    get formBuilder() {
      return (0, _serialization.deserializeModel)(this.args.module.body.find(i => {
        const model = (0, _serialization.deserializeModel)(i);
        return model.componentType === 'form';
      }));
    }

    get formFields() {
      return this.formBuilder.inputFields.map(entry => (0, _serialization.deserializeModel)(entry));
    }

    get isFormValid() {
      return !Object.keys(this.lead).map(i => {
        const field = this.lead[i];
        return !(field.value === undefined || field.value === null) && this.lead[i].isValid;
      }).some(i => !i);
    }

    clearForm() {
      const lead = {};
      this.formFields.forEach(({
        name
      }) => {
        lead[name] = {
          value: undefined,
          isValid: false
        };
      });
      this.lead = lead;
    }

    *pushLead() {
      const {
        category
      } = this.formBuilder;
      const [categoryId, categoryName] = category.split('#');
      const data = {
        category_ident: categoryId,
        source_data: {
          anonymous_lead: true,
          terms_accepted: this.termsAccepted,
          adjust: {
            utm_medium: 'seo'
          }
        }
      };
      Object.keys(this.lead).forEach(i => {
        data[i] = this.lead[i].value;
      });

      try {
        this.serverError = false;
        const {
          lead
        } = yield this.api.post('leads/lead_with_opportunity', data);
        this.closeModal();
        this.tracking.trackWithGTM(_constants.TrackingEvent.SUBMIT_LEAD_GEN_FORM);
        this.router.transitionTo('lead-success', lead.mandate.first_name, {
          queryParams: {
            category: categoryName
          }
        });
      } catch (error) {
        if (error.body) {
          this.serverError = error.body.error;
        }
      }
    }

    closeModal() {
      this.clearForm();
      this.modal.leadGen = false;
    }

    update(lead) {
      this.lead = lead;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "api", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tracking", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "lead", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "serverError", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "termsAccepted", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "pushLead", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "pushLead"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype)), _class));
  _exports.default = LeadGenModal;
});