define("cms-frontend/components/wrapper-components/picture-frame/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4RszNaoz",
    "block": "{\"symbols\":[\"imgSrc1\",\"imgSrc2\",\"imgSrc3\",\"@module\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,4,[\"imgSrc1\"]]],null],[30,[36,0],[[32,4,[\"imgSrc2\"]]],null],[30,[36,0],[[32,4,[\"imgSrc3\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"picture-frame\",[],[[\"@imgSrc1\",\"@imgSrc2\",\"@imgSrc3\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[1,2,3]}]]]],\"hasEval\":false,\"upvars\":[\"transform-contentful-media\",\"let\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/picture-frame/template.hbs"
    }
  });

  _exports.default = _default;
});