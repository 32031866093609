define("cms-frontend/components/wrapper-components/seo-hero/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ismpD6v8",
    "block": "{\"symbols\":[\"leadGen\",\"@module\"],\"statements\":[[8,\"seo-hero\",[],[[\"@title\",\"@description\",\"@imgSrc\",\"@imgSrcMobile\"],[[32,2,[\"heading\"]],[32,2,[\"description\"]],[30,[36,3],[[32,2,[\"imgSrc\"]]],null],[30,[36,3],[[32,2,[\"mobileImg\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[32,2,[\"leadGenButton\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2,[\"leadGenButton\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"lead-gen-cta\",[[4,[38,0],[\"click\",[32,0,[\"sendTrackingEvent\"]]],null]],[[\"@text\",\"@appearance\"],[[32,1,[\"text\"]],\"ghost\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"transform-contentful\",\"let\",\"transform-contentful-media\",\"if\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/seo-hero/template.hbs"
    }
  });

  _exports.default = _default;
});