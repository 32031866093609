define("cms-frontend/components/wrapper-components/success-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YAgJMW5+",
    "block": "{\"symbols\":[\"button\",\"imgSrc\",\"@module\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,1],[\"success-module-wrapper\"],[[\"from\"],[\"cms-frontend/components/wrapper-components/success-message/styles\"]]]]]],[14,\"role\",\"presentation\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,\"role\",\"presentation\"],[15,0,[30,[36,2],[\"container\"],null]],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,3,[\"button\"]]],null],[30,[36,3],[[32,3,[\"imgSrc\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"success-message\",[],[[\"@title\",\"@description\",\"@button\",\"@imgSrc\"],[[32,3,[\"title\"]],[32,3,[\"description\"]],[30,[36,0],null,[[\"text\",\"href\"],[[32,1,[\"text\"]],[32,1,[\"url\"]]]]],[30,[36,0],null,[[\"url\",\"altText\"],[[32,2,[\"url\"]],[32,2,[\"altText\"]]]]]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"local-class\",\"grid\",\"transform-contentful-image\",\"transform-contentful\",\"let\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/success-message/template.hbs"
    }
  });

  _exports.default = _default;
});