define("cms-frontend/components/wrapper-components/lead-gen/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GnCKrrDR",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[8,\"lead-gen/section\",[[24,1,\"lead-gen\"]],[[\"@highMargin\",\"@formImage\",\"@formImageMobile\",\"@formTitle\",\"@formDescription\",\"@cta\",\"@successTitle\",\"@successDescription\",\"@onHighMarginFormSubmit\",\"@isSuccess\",\"@highMarginFormError\",\"@highMarginFormFieldErrors\",\"@consultantCard\",\"@consultantText\"],[[32,1,[\"highMargin\"]],[30,[36,0],[[32,1,[\"formImage\"]]],null],[30,[36,0],[[32,1,[\"formImageMobile\"]]],null],[32,1,[\"formTitle\"]],[30,[36,1],[[32,1,[\"formDescription\"]]],null],[30,[36,2],[[32,1,[\"cta\"]]],null],[32,0,[\"formattedSuccessTitle\"]],[32,0,[\"formattedSuccessDescription\"]],[30,[36,3],[[32,0,[\"handleHighMarginFormSubmit\"]]],null],[32,0,[\"isSuccess\"]],[32,0,[\"highMarginFormError\"]],[32,0,[\"highMarginFormFieldErrors\"]],[30,[36,2],[[32,1,[\"consultantCard\"]]],null],[32,1,[\"sideHeader\"]]]],null]],\"hasEval\":false,\"upvars\":[\"transform-contentful-image\",\"transform-contentful-rich-text\",\"deep-transform-contentful\",\"perform\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/lead-gen/template.hbs"
    }
  });

  _exports.default = _default;
});