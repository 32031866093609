define("cms-frontend/services/meta", ["exports", "ember-window-mock", "cms-frontend/utils/serialization", "cms-frontend/utils/structured-data"], function (_exports, _emberWindowMock, _serialization, _structuredData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const URL_VALIDATOR = /((https|http)?):\/\/((?:(?![#&/?]).)+)?(?:((?:(?!(?:\.|$|\?|#)).)+))?((\/\w+)+|\/?)$/;
  const LOCALHOST_VALIDATOR = /\b(\w*localhost\w*)\b/gi;
  const validSlugsForAlternateLinks = new Set(['/', 'cms/so-funktionierts', 'cms/ueber-uns', 'cms/partnerships']);
  let MetaService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class MetaService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fastboot", _descriptor, this);

      _initializerDefineProperty(this, "config", _descriptor2, this);
    }

    createHeadTags(metadata, slug) {
      const value = [];
      value.push({
        type: 'meta',
        tagId: 'meta-og-type',
        attrs: {
          property: 'og:type',
          content: 'website'
        }
      });
      value.push({
        type: 'meta',
        tagId: 'meta-og-locale',
        attrs: {
          property: 'og:locale',
          content: this.config.getConfig('locale.languageTag')
        }
      });
      value.push({
        type: 'meta',
        tagId: 'meta-og-site-name',
        attrs: {
          property: 'og:og:site_name',
          content: this.config.getConfig('brandFull')
        }
      });

      if (metadata.metaTitle) {
        value.push({
          type: 'title',
          content: metadata.metaTitle
        });
        value.push({
          type: 'meta',
          tagId: 'meta-og-title',
          attrs: {
            property: 'og:title',
            content: metadata.metaTitle
          }
        });
      }

      if (metadata.metaDescription) {
        value.push({
          type: 'meta',
          tagId: 'meta-description-tag',
          attrs: {
            name: 'description',
            content: metadata.metaDescription
          }
        });
        value.push({
          type: 'meta',
          tagId: 'meta-og-description',
          attrs: {
            property: 'og:description',
            content: metadata.metaDescription
          }
        });
      }

      if (metadata.metaRobots) {
        value.push({
          type: 'meta',
          tagId: 'meta-robot-id',
          attrs: {
            name: 'robots',
            content: metadata.metaRobots
          }
        });
      }

      if (metadata.ogImage) {
        const image = (0, _serialization.deserializeImageModel)(metadata.ogImage);
        value.push({
          type: 'meta',
          tagId: 'meta-og-image-url',
          attrs: {
            property: 'og:image',
            content: image.url.slice(2)
          }
        });
        value.push({
          type: 'meta',
          tagId: 'meta-og-image-alt',
          attrs: {
            property: 'og:image:alt',
            content: image.altText
          }
        });
        value.push({
          type: 'meta',
          tagId: 'meta-og-image-width',
          attrs: {
            property: 'og:image:width',
            content: '1200'
          }
        });
        value.push({
          type: 'meta',
          tagId: 'meta-og-image-height',
          attrs: {
            property: 'og:image:height',
            content: '630'
          }
        });
      }

      if (metadata.ogVideo) {
        value.push({
          type: 'meta',
          tagId: 'meta-og-video',
          attrs: {
            property: 'og:video',
            content: metadata.ogVideo
          }
        });
      }

      const canonicalTag = this.createCanonicalTag();

      if (canonicalTag) {
        value.push(canonicalTag);
        value.push({
          type: 'meta',
          tagId: 'meta-og-url',
          attrs: {
            property: 'og:url',
            content: canonicalTag.attrs.href
          }
        });
      }

      if (metadata.structuredData) {
        value.push({
          type: 'script',
          tagId: 'structured-data',
          attrs: {
            type: 'application/ld+json'
          },
          content: (0, _structuredData.default)(metadata.structuredData)
        });
      }

      if (slug && validSlugsForAlternateLinks.has(slug)) {
        const deDomain = 'https://www.clark.de/';
        const atDomain = 'https://www.goclark.at/';
        value.push({
          type: 'link',
          tagId: 'link-alternate-de-de',
          attrs: {
            rel: 'alternate',
            hreflang: 'de-DE',
            href: slug === '/' ? deDomain : `${deDomain}${slug}/`
          }
        });
        value.push({
          type: 'link',
          tagId: 'link-alternate-de-at',
          attrs: {
            rel: 'alternate',
            hreflang: 'de-AT',
            href: slug === '/' ? atDomain : `${atDomain}${slug}/`
          }
        });
      }

      return value;
    }

    createCanonicalTag() {
      let href;

      if (this.fastboot.isFastBoot) {
        const {
          request
        } = this.fastboot;
        let {
          host
        } = request;

        if (host.match(LOCALHOST_VALIDATOR)) {
          host = this.config.getConfig('canonicalHost');
        }

        href = request.path === '/' ? `https://www.${host}${request.path}`.replace(/\/$/, '') : `https://www.${host}${request.path}/`;
      } else {
        ({
          href
        } = _emberWindowMock.default.location);
      }

      const result = URL_VALIDATOR.exec(href);

      if (result) {
        [href] = result;
        return {
          type: 'link',
          attrs: {
            rel: 'canonical',
            href: href[href.length - 1] === '/' ? href : `${href}/`
          }
        };
      }

      return null;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "config", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MetaService;
});