define("cms-frontend/components/cookie-banner/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency-ts", "ember-window-mock", "cms-frontend/utils/constants"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrencyTs, _emberWindowMock, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CookieBannerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, (_class = (_temp = class CookieBannerComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args); // set defaults

      _initializerDefineProperty(this, "cookies", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "fastboot", _descriptor3, this);

      _initializerDefineProperty(this, "config", _descriptor4, this);

      _initializerDefineProperty(this, "api", _descriptor5, this);

      _initializerDefineProperty(this, "tracking", _descriptor6, this);

      _initializerDefineProperty(this, "cookieBanner", _descriptor7, this);

      _initializerDefineProperty(this, "termsAccepted", _descriptor8, this);

      _initializerDefineProperty(this, "allowTrackingMarketing", _descriptor9, this);

      _initializerDefineProperty(this, "consentCategories", _descriptor10, this);

      _initializerDefineProperty(this, "consentUIOptions", _descriptor11, this);

      _initializerDefineProperty(this, "consentStatement", _descriptor12, this);

      _initializerDefineProperty(this, "cookieContents", _descriptor13, this);

      _initializerDefineProperty(this, "isUserOrLead", _descriptor14, this);

      this.consentCategories = {
        functional_cookies: true
      }; // get & set consent statement

      this.consentStatement = this.readConsentStatement(); // only continue if consent statement is present

      if (this.consentStatement) {
        // delete outdated cookies by version num
        this.cleanStorageItems(); // try to read existing consent cookie

        this.readConsentChoiceCookie(); // build options array

        this.prepareConsentUIOptions();
        (0, _emberConcurrencyTs.taskFor)(this.fetchCurrentUser).perform(); // kick off js loading if consent is given

        this.loadAllowedScripts();
      }
    }

    *fetchCurrentUser() {
      let response;

      try {
        response = yield this.api.get('current_user');
      } catch {
        return;
      }

      this.isUserOrLead = response.user || response.lead;
    }

    get isLighthouse() {
      // Hide cookie banner for Google Page Speed Insights
      return !this.isBrowserContext() ? false : _emberWindowMock.default.navigator.userAgent.indexOf('Chrome-Lighthouse') > 0;
    }

    get showCookieBanner() {
      return this.cookieBanner.isVisible;
    }

    get showAdvancedCookies() {
      return this.cookieBanner.canShowAdvancedCookies;
    }

    getCurrentConsentVersion() {
      // eslint-disable-next-line radix
      return Number.parseInt(this.consentStatement.attributes.version);
    }

    prepareConsentUIOptions() {
      const {
        categories
      } = this.consentStatement.attributes;
      this.consentUIOptions = [];

      for (const category of categories) {
        const option = {
          title: category.title,
          identifier: category.identifier,
          description: category.description,
          dataPrivacyUrl: category.dataPrivacyUrl,
          isChecked: this.consentCategories[category.identifier],
          isDisabled: category.identifier === 'functional_cookies'
        };
        this.consentUIOptions.push(option);
      }
    }

    isBrowserContext() {
      return typeof _emberWindowMock.default !== 'undefined' && typeof _emberWindowMock.default.location !== 'undefined';
    }

    removeNonFunctionalStorageItems() {
      if (!this.isBrowserContext()) {
        return false;
      }

      const domainWithSubdomains = _emberWindowMock.default.location.hostname;
      const domain = domainWithSubdomains.split('.').slice(-2).join('.');
      const allCookies = document.cookie.split(';');
      const anyUTCDateInPast = 'Thu, 01 Jan 1970 00:00:00 UTC';
      const functionalCookies = new Set([_constants.CookieName.CONSENT_MGMT_COOKIE_NAME, '_optisure_session', 'ahoy_visitor', 'ahoy_visit']);
      const domainsToDelete = [`.${domainWithSubdomains}`, domainWithSubdomains, `.${domain}`, domain];

      for (const cookie of allCookies) {
        const eqPos = cookie.indexOf('=');
        const cookieName = eqPos > -1 ? cookie.slice(0, Math.max(0, eqPos)).trim() : cookie;

        if (!functionalCookies.has(cookieName)) {
          domainsToDelete.forEach(domainName => {
            document.cookie = `${cookieName}=; expires=${anyUTCDateInPast}; path=/; domain=${domainName}`;
          });
        }
      }

      localStorage.clear();
    }

    cleanStorageItems() {
      const consentCookieName = _constants.CookieName.CONSENT_MGMT_COOKIE_NAME;
      const cookieExists = this.cookies.exists(consentCookieName);
      const cookieContents = this.getCookieContents(consentCookieName);
      const versionNum = this.getCurrentConsentVersion();

      if (!cookieExists || !this.consentIsCurrent(cookieContents, versionNum) || !this.consentCategoryIsEnabled('marketing_&_tracking')) {
        this.removeNonFunctionalStorageItems();
      }
    }

    consentCategoryIsEnabled(category) {
      const cookieContents = this.getCookieContents(_constants.CookieName.CONSENT_MGMT_COOKIE_NAME);

      if (!cookieContents) {
        return false;
      }

      const filteredCategory = cookieContents.categories.find(cookieCategory => cookieCategory.identifier === category);

      if (filteredCategory) {
        return filteredCategory.enabled;
      }
    }

    getCookieContents(cookieName) {
      try {
        return this.cookies.exists(cookieName) && JSON.parse(this.cookies.read(cookieName, {
          raw: true
        }));
      } catch {
        return false;
      }
    }

    setCookie(cookie, value) {
      const date = new Date();
      date.setTime(date.getTime() + _constants.Constants.COOKIE_TRACKING_TIME_IN_DAYS * 24 * 60 * 60 * 1000);
      this.cookies.write(cookie, value, {
        raw: true,
        expires: date,
        path: '/'
      });
    }

    readConsentStatement() {
      const statementRaw = this.getConsentStatement();
      return statementRaw.data || false;
    }

    consentIsCurrent(cookieContents, versionNum) {
      return cookieContents.version && cookieContents.version === versionNum;
    }

    readConsentChoiceCookie() {
      const consentStatementVersion = this.getCurrentConsentVersion();
      const cookieContents = this.getCookieContents(_constants.CookieName.CONSENT_MGMT_COOKIE_NAME);

      if (cookieContents && cookieContents.categories.length > 0 && this.consentIsCurrent(cookieContents, consentStatementVersion)) {
        cookieContents.categories.forEach(category => {
          this.consentCategories[category.identifier] = category.enabled;
        });
        _emberWindowMock.default.consentCategoriesMap = this.consentCategories;
      } else {
        this.cookieBanner.toggleShowCookieBanner(true);
      }
    }

    writeConsentChoiceCookie() {
      // compose cookie contents
      const serializedConsent = this.getSerializedConsent(); // write out new cookie contents

      this.setCookie(_constants.CookieName.CONSENT_MGMT_COOKIE_NAME, JSON.stringify(serializedConsent));
    }

    loadAllowedScripts() {
      if (this.isBrowserContext() && this.consentCategoryIsEnabled('marketing_&_tracking')) {
        _emberWindowMock.default.loadClarkTrackingScript();
      }
    }

    saveConsentIfAuthorized() {
      if (!this.isUserOrLead) {
        return false;
      }

      const serializedConsent = this.getSerializedConsent();
      this.api.post('customer/current/consent', {
        data: {
          type: 'consent',
          attributes: serializedConsent
        }
      });
    }

    getSerializedConsent() {
      const newConsentCookieContents = {
        version: this.getCurrentConsentVersion(),
        categories: []
      };
      const {
        categories
      } = this.consentStatement.attributes;

      for (const category of categories) {
        const categoryIdentifier = category.identifier;
        newConsentCookieContents.categories.push({
          identifier: categoryIdentifier,
          enabled: !!this.consentCategories[categoryIdentifier]
        });
      }

      return newConsentCookieContents;
    }

    applyConsentChoice() {
      this.cookieBanner.toggleShowCookieBanner(false);
      this.writeConsentChoiceCookie();
      this.readConsentChoiceCookie();
      this.saveConsentIfAuthorized();
      this.prepareConsentUIOptions();

      if (this.isBrowserContext() && this.cookieBanner.isUpdate) {
        _emberWindowMock.default.location.reload();
      }

      this.loadAllowedScripts();
    }

    closeBanner() {
      this.applyConsentChoice();
      this.tracking.track(_constants.TrackingEvent.COOKIE_CLOSE);
    }

    onClickDenyAll() {
      const {
        categories
      } = this.consentStatement.attributes;
      categories.forEach(category => {
        this.consentCategories[category.identifier] = category.identifier === 'functional_cookies';
      });
      this.applyConsentChoice();
    }

    onClickAcceptAll() {
      const {
        categories
      } = this.consentStatement.attributes;
      categories.forEach(category => {
        this.consentCategories[category.identifier] = true;
      });
      this.applyConsentChoice();
    }

    onClickSave() {
      this.applyConsentChoice();
      this.tracking.track(this.cookieBanner.canShowAdvancedCookies ? _constants.TrackingEvent.COOKIE_ACCEPT_UNCOLLAPSED : _constants.TrackingEvent.COOKIE_ACCEPT_COLLAPSED);
    }

    openMoreInfo() {
      this.tracking.track(_constants.TrackingEvent.COOKIE_MORE_INFO);
    }

    toggleAdvanced() {
      this.cookieBanner.toggleShowAdvancedCookies();
    }

    toggleConsentCategory(categoryIdentifier) {
      this.consentCategories[categoryIdentifier] = !this.consentCategories[categoryIdentifier];
    }

    getConsentStatement() {
      return {
        data: {
          attributes: {
            version: 1,
            name: 'Wir verwenden Cookies',
            description: 'Wir verwenden Cookies, um Clark-Nutzern die bestmögliche Browsing-Erfahrung zu bieten. Wähle ”Akzeptieren”, um alle gewählten Arten von Cookies zu akzeptieren.',
            categories: [{
              title: 'Funktionale Cookies',
              dataPrivacyUrl: '/de/datenschutz#funktionale-cookies',
              description: 'Diese Cookies sind zur Funktion von CLARK erforderlich und können nicht deaktiviert werden. In der Regel werden diese Cookies nur als Reaktion auf von dir getätigte Aktionen gesetzt, die einer Dienstanforderung entsprechen, wie etwa dem Festlegen deiner Datenschutzeinstellungen, dem Anmelden oder dem Ausfüllen von Formularen. Du kannst deinen Browser so einstellen, dass diese Cookies blockiert werden oder du über diese Cookies benachrichtigt wirst. Einige Bereiche von CLARK funktionieren dann aber nicht.',
              identifier: 'functional_cookies',
              required: true
            }, {
              title: 'Marketing & Analyse Cookies',
              dataPrivacyUrl: '/de/datenschutz#analytics-cookies',
              description: 'Marketing-Cookies werden normalerweise verwendet, um dir Anzeigen anzuzeigen, die deinen Interessen entsprechen. Wenn Du eine andere Website besuchst, wird das Cookie deines Browsers erkannt und ausgewählte Anzeigen werden dir basierend auf den in diesem Cookie gespeicherten Informationen angezeigt. Wir geben diese Informationen zur weiteren Verarbeitung an unsere Dienstleister weiter. Wenn du diese Cookies nicht zulässt, können dir keine Anzeigen angezeigt werden, die deinen Interessen entsprechen. Analyse-Cookies ermöglichen es uns, Besuche und Verkehrsquellen zu zählen, damit wir die Leistung von CLARK messen und verbessern können. Du unterstützt uns bei der Beantwortung der Fragen, welche Angebote am beliebtesten sind, welche am wenigsten genutzt werden und wie sich Besucher auf CLARK bewegen. Alle von diesen Cookies erfassten Informationen werden aggregiert und sind deshalb pseudonym. Wir geben diese Informationen zur weiteren Verarbeitung an unsere Dienstleister weiter. Wenn du diese Cookies nicht zulässt, können wir nicht wissen, wann du CLARK besucht hast.',
              identifier: 'marketing_&_tracking',
              required: false
            }]
          }
        }
      };
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "cookies", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "config", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "api", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tracking", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "cookieBanner", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "termsAccepted", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "allowTrackingMarketing", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "consentCategories", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "consentUIOptions", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "consentStatement", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "cookieContents", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isUserOrLead", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchCurrentUser", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCurrentUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeBanner", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "closeBanner"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickDenyAll", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "onClickDenyAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickAcceptAll", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "onClickAcceptAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickSave", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "onClickSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openMoreInfo", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "openMoreInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAdvanced", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAdvanced"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleConsentCategory", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "toggleConsentCategory"), _class.prototype)), _class));
  _exports.default = CookieBannerComponent;
});