define("cms-frontend/router", ["exports", "cms-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    // Preview mode routes
    if (_environment.default.isPreviewMode) {
      // NOTE: The duplicated `preview.not-found` route is intentional and this
      // doesn't work as expected otherwise. What it does is basically routes
      // the requests to any URL (including `/` and `/preview`) to
      // the `preview.not-found` route handler, only leaving `/preview/:entry_id`
      // to be handled by the `preview.entry`.
      // Feel free to improve this if there's a better approach :)
      this.route('preview', {
        path: '/'
      }, function () {
        this.route('entry', {
          path: '/preview/:entry_id'
        });
        this.route('not-found', {
          path: '/'
        });
        this.route('not-found', {
          path: '/*'
        });
      });
      return;
    } // Regular routes


    this.route('404');
    this.route('sovendus');
    this.route('lead-success', {
      path: '/success/:lead'
    });
    this.route('content', {
      path: '/*slug'
    });
  });
});