define("cms-frontend/components/wrapper-components/rich-text/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R8WRHpsJ",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[8,\"common/table\",[],[[\"@model\"],[[32,1,[\"table\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/rich-text/table/template.hbs"
    }
  });

  _exports.default = _default;
});