define("cms-frontend/modifiers/on-viewport-relative-scroll", ["exports", "@clark/cms-ui/modifiers/on-viewport-relative-scroll"], function (_exports, _onViewportRelativeScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _onViewportRelativeScroll.default;
    }
  });
});