define("cms-frontend/components/wrapper-components/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q9D9CLX8",
    "block": "{\"symbols\":[\"&attrs\",\"&default\",\"@componentType\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,1],[[32,3]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"section\"],[16,0,[31,[[30,[36,0],[\"section-wrapper\"],[[\"from\"],[\"cms-frontend/components/wrapper-components/styles\"]]]]]],[17,1],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"local-class\",\"kebab-case\",\"is-self-managed-layout\",\"if\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/template.hbs"
    }
  });

  _exports.default = _default;
});