define("cms-frontend/components/wrapper-components/hero/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M3X3nkF3",
    "block": "{\"symbols\":[\"heroBtn\",\"@module\"],\"statements\":[[8,\"hero\",[],[[\"@mobileImgSrc\",\"@imgSrc\",\"@title\",\"@description\"],[[30,[36,3],[[32,2,[\"mobileImgSrc\"]]],null],[30,[36,3],[[32,2,[\"imgSrc\"]]],null],[32,2,[\"heading\"]],[32,2,[\"description\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,2,[\"heroButton\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"button\",[],[[\"@appearance\",\"@url\"],[[30,[36,1],[[30,[36,0],[[32,1,[\"isSecondary\"]]],null],\"primary\",\"secondary\"],null],[30,[36,1],[[30,[36,2],[\"any\"],null],[32,1,[\"mobileLink\"]],[32,1,[\"desktopLink\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,1],[[30,[36,2],[\"any\"],null],[32,1,[\"mobileText\"]],[32,1,[\"desktopText\"]]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"leadButton\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"button\",[],[[\"@appearance\",\"@url\"],[\"secondary\",[32,0,[\"leadButton\",\"url\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,0,[\"leadButton\",\"title\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"not\",\"if\",\"is-mobile\",\"transform-contentful-media\",\"transform-contentful\",\"let\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/hero/template.hbs"
    }
  });

  _exports.default = _default;
});