define("cms-frontend/helpers/deep-transform-contentful", ["exports", "cms-frontend/utils/serialization"], function (_exports, _serialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DeepTransformContentfulHelper extends Ember.Helper {
    compute([model]) {
      return (0, _serialization.deepDeserializeModel)(model);
    }

  }

  _exports.default = DeepTransformContentfulHelper;
});