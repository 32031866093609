define("cms-frontend/helpers/transform-contentful-rich-text", ["exports", "@contentful/rich-text-html-renderer", "@contentful/rich-text-types", "cms-frontend/components/wrapper-components/rich-text/styles", "cms-frontend/utils/rich-text-header"], function (_exports, _richTextHtmlRenderer, _richTextTypes, _styles, _richTextHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @ts-ignore
  function transformContentfulRichText(parameters) {
    const [node] = parameters;
    if (!node) return undefined;
    const options = {
      renderNode: {
        [_richTextTypes.BLOCKS.HEADING_2]: (headerNode, next) => {
          return `<h2 id="${(0, _richTextHeader.default)(headerNode)}" class="${_styles.default['rich-text-header-anchor']}">${next(headerNode.content)}</h2>`;
        },
        [_richTextTypes.BLOCKS.UL_LIST]: (unorderedListNode, next) => {
          return `<ul class="${_styles.default['rich-text-unordered-list']}">${next(unorderedListNode.content)}</ul>`;
        },
        [_richTextTypes.BLOCKS.PARAGRAPH]: (paragraphNode, next) => {
          const nodesWithValue = paragraphNode.content.filter(element => {
            return Ember.isPresent(element.value);
          });
          const classes = Ember.isPresent(nodesWithValue) ? '' : _styles.default['empty-paragraph'];
          return `<p class="${classes}">${next(paragraphNode.content)}</p>`;
        },
        [_richTextTypes.BLOCKS.HR]: () => {
          return `<hr class="${_styles.default['line-breaker']}" />`;
        }
      }
    };

    if (node.nodeType !== 'document') {
      return (0, _richTextHtmlRenderer.documentToHtmlString)({
        data: {},
        content: [node],
        nodeType: _richTextTypes.BLOCKS.DOCUMENT
      }, options);
    }

    return Ember.String.htmlSafe((0, _richTextHtmlRenderer.documentToHtmlString)(node, options));
  }

  var _default = Ember.Helper.helper(transformContentfulRichText);

  _exports.default = _default;
});