define("cms-frontend/components/wrapper-components/hero-small/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EXo2PU36",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[8,\"hero-small\",[],[[\"@title\",\"@description\",\"@imgSrc\"],[[32,1,[\"heading\"]],[32,1,[\"description\"]],[30,[36,0],[[32,1,[\"imgSrc\"]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"transform-contentful-media\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/hero-small/template.hbs"
    }
  });

  _exports.default = _default;
});