define("cms-frontend/components/preview/error-screen/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mTWXumDD",
    "block": "{\"symbols\":[\"@entryId\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"error-screen\"],[[\"from\"],[\"cms-frontend/components/preview/error-screen/styles\"]]]]]],[14,\"data-test-error-screen\",\"\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"layout-container\"],[[\"from\"],[\"cms-frontend/components/preview/error-screen/styles\"]]]]]],[12],[2,\"\\n    \"],[10,\"h3\"],[15,0,[31,[[30,[36,0],[\"message\"],[[\"from\"],[\"cms-frontend/components/preview/error-screen/styles\"]]]]]],[14,\"data-test-error-screen-message\",\"\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"preview.error-screen.message\"],null]],[2,\"\\n\"],[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"strong\"],[14,\"data-test-error-screen-entry-id\",\"\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[15,0,[31,[[30,[36,0],[\"hint\"],[[\"from\"],[\"cms-frontend/components/preview/error-screen/styles\"]]]]]],[14,\"data-test-error-screen-hint\",\"\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"preview.error-screen.hint\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/preview/error-screen/template.hbs"
    }
  });

  _exports.default = _default;
});