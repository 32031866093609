define("cms-frontend/components/wrapper-components/highlight/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qn8GTzpu",
    "block": "{\"symbols\":[\"HighlightBox\",\"cta\",\"@module\"],\"statements\":[[6,[37,4],[[30,[36,5],[\"highlight-box\"],[[\"title\",\"description\"],[[32,3,[\"title\"]],[32,3,[\"description\"]]]]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,3,[\"cta\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,3,[\"cta\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"button\",[[16,0,[31,[[30,[36,0],[\"cta-button\"],[[\"from\"],[\"cms-frontend/components/wrapper-components/highlight/styles\"]]]]]],[24,\"data-test-highlight-box-cta-button\",\"\"]],[[\"@url\"],[[30,[36,2],[[30,[36,1],[\"any\"],null],[32,2,[\"mobileLink\"]],[32,2,[\"desktopLink\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,2],[[30,[36,1],[\"any\"],null],[32,2,[\"mobileText\"]],[32,2,[\"desktopText\"]]],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,[32,1],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"local-class\",\"is-mobile\",\"if\",\"transform-contentful\",\"let\",\"component\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/highlight/template.hbs"
    }
  });

  _exports.default = _default;
});