define("cms-frontend/components/wrapper-components/two-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KdpNsqat",
    "block": "{\"symbols\":[\"header\",\"footer\",\"@module\"],\"statements\":[[6,[37,2],[[30,[36,1],[[32,3,[\"header\"]]],null],[30,[36,1],[[32,3,[\"footer\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"two-column\",[],[[\"@header\",\"@content\",\"@footer\"],[[30,[36,0],null,[[\"title\",\"description\"],[[32,1,[\"heading\"]],[32,1,[\"description\"]]]]],[32,3,[\"content\"]],[30,[36,0],null,[[\"mobile\",\"desktop\",\"isSecondary\"],[[30,[36,0],null,[[\"text\",\"href\"],[[32,2,[\"mobileText\"]],[32,2,[\"mobileLink\"]]]]],[30,[36,0],null,[[\"text\",\"href\"],[[32,2,[\"desktopText\"]],[32,2,[\"desktopLink\"]]]]],[32,2,[\"isSecondary\"]]]]]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"transform-contentful\",\"let\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/two-column/template.hbs"
    }
  });

  _exports.default = _default;
});