define("cms-frontend/components/wrapper-components/next-steps/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dCPR9Wu6",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[8,\"next-steps\",[],[[\"@items\"],[[30,[36,1],[[32,1,[\"items\"]],[30,[36,0],[[32,1,[\"items\"]]],null]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"transform-contentful\",\"if\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/next-steps/template.hbs"
    }
  });

  _exports.default = _default;
});