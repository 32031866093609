define("cms-frontend/components/wrapper-components/expert-tip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EBf2GvC2",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[8,\"expert-tip\",[],[[\"@title\",\"@quote\",\"@variant\",\"@expert\"],[[32,1,[\"title\"]],[32,1,[\"quote\"]],[32,1,[\"variant\"]],[30,[36,0],[[32,1,[\"salesExpert\"]]],null]]],null]],\"hasEval\":false,\"upvars\":[\"deep-transform-contentful\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/expert-tip/template.hbs"
    }
  });

  _exports.default = _default;
});