define("cms-frontend/components/wrapper-components/media-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KPpKLWji",
    "block": "{\"symbols\":[\"footer\",\"@module\"],\"statements\":[[8,\"media-box\",[],[[\"@imgSource\",\"@title\",\"@description\",\"@orientation\"],[[30,[36,7],[[32,2,[\"imgSrc\"]]],null],[32,2,[\"heading\"]],[32,2,[\"description\"]],[32,2,[\"orientation\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,2,[\"footerContent\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[30,[36,4],[[32,2,[\"footerContent\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"componentType\"]],\"switchButton\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"button\",[],[[\"@appearance\",\"@url\"],[[30,[36,1],[[30,[36,2],[[32,1,[\"isSecondary\"]]],null],\"primary\",\"secondary\"],null],[30,[36,1],[[30,[36,3],[\"any\"],null],[32,1,[\"mobileLink\"]],[32,1,[\"desktopLink\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,1],[[30,[36,3],[\"any\"],null],[32,1,[\"mobileText\"]],[32,1,[\"desktopText\"]]],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"componentType\"]],\"appStore\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"common/app-store\",[],[[\"@appStoreLink\",\"@playStoreLink\"],[[32,1,[\"appStoreLink\"]],[32,1,[\"playStoreLink\"]]]],null],[2,\"\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"not\",\"is-mobile\",\"transform-contentful\",\"-track-array\",\"each\",\"transform-contentful-media\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/media-box/template.hbs"
    }
  });

  _exports.default = _default;
});