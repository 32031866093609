define("cms-frontend/utils/sentry", ["exports", "@sentry/browser", "@sentry/integrations/esm", "cms-frontend/config/environment", "cms-frontend/utils/sentry-error-filter"], function (_exports, Sentry, _esm, _environment, _sentryErrorFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initSentry = initSentry;
  _exports.captureException = captureException;
  const FILTERS = [/^Test/];

  function initSentry() {
    try {
      Sentry.init({ ..._environment.default.sentry,
        release: _environment.default.APP.version,
        integrations: [new _esm.Ember(), new Sentry.Integrations.Breadcrumbs({
          console: false
        }), new Sentry.Integrations.GlobalHandlers({
          onerror: true,
          onunhandledrejection: true
        })],
        beforeSend: (0, _sentryErrorFilter.filterFactory)(FILTERS)
      });

      if (_environment.default.sentryTags) {
        Sentry.configureScope(scope => {
          scope.setTags(_environment.default.sentryTags);
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  function captureException(error, message) {
    Sentry.withScope(scope => {
      scope.setExtras({
        message
      });
      Sentry.captureException(error);
    });
  }
});