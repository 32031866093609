define("cms-frontend/components/wrapper-components/contact-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x96PkRty",
    "block": "{\"symbols\":[\"avatar\",\"button\",\"@module\"],\"statements\":[[6,[37,2],[[30,[36,3],[[32,3,[\"avatar\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"contact-box-wrapper\"],[[\"from\"],[\"cms-frontend/components/wrapper-components/contact-box/styles\"]]]]]],[12],[2,\"\\n    \"],[8,\"contact-box\",[],[[\"@avatar\",\"@title\",\"@description\"],[[32,1],[32,3,[\"heading\"]],[32,3,[\"description\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,3,[\"leadGenButton\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"lead-gen-cta\",[],[[\"@text\"],[[32,2,[\"text\"]]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"local-class\",\"transform-contentful\",\"let\",\"transform-contentful-image\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/contact-box/template.hbs"
    }
  });

  _exports.default = _default;
});