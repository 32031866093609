define("cms-frontend/components/wrapper-components/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VH/qUS5S",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[8,\"button\",[],[[\"@appearance\",\"@url\"],[[30,[36,0],[[32,1,[\"isPrimmaryButton\"]],\"primary\",\"secondary\"],null],[32,1,[\"url\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,1,[\"text\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/button/template.hbs"
    }
  });

  _exports.default = _default;
});