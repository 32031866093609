define("cms-frontend/helpers/nullable-bool", ["exports", "@clark/cms-ui/helpers/nullable-bool"], function (_exports, _nullableBool) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _nullableBool.default;
    }
  });
  Object.defineProperty(_exports, "nullableBool", {
    enumerable: true,
    get: function () {
      return _nullableBool.nullableBool;
    }
  });
});