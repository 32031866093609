define("cms-frontend/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tbN8uiuX",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"layout\",[[24,\"data-test-content-route\",\"\"]],[[\"@modules\",\"@leadGenData\",\"@headerCtaButtonData\",\"@modals\"],[[32,1,[\"modules\"]],[32,1,[\"leadGenData\"]],[32,1,[\"headerCtaButtonData\"]],[32,1,[\"modals\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "cms-frontend/content/template.hbs"
    }
  });

  _exports.default = _default;
});