define("cms-frontend/components/wrapper-components/three-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C5FYFEBK",
    "block": "{\"symbols\":[\"model\",\"@module\"],\"statements\":[[6,[37,4],[[30,[36,0],null,[[\"header\",\"items\",\"footer\"],[[30,[36,1],[[32,2,[\"header\"]]],null],[30,[36,3],[[30,[36,1],[[32,2,[\"items\"]]],null]],[[\"prop\"],[\"imgSrc\"]]],[30,[36,2],[[32,2,[\"footerBtn\"]],[30,[36,1],[[32,2,[\"footerBtn\"]]],null],null],null]]]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"thumbnail-list\",[],[[\"@header\",\"@items\",\"@footer\"],[[30,[36,0],null,[[\"title\",\"description\"],[[32,1,[\"header\",\"heading\"]],[32,1,[\"header\",\"description\"]]]]],[32,1,[\"items\"]],[30,[36,0],null,[[\"isSecondary\",\"mobile\",\"desktop\"],[[32,1,[\"footer\",\"isSecondary\"]],[30,[36,0],null,[[\"text\",\"href\"],[[32,1,[\"footer\",\"mobileText\"]],[32,1,[\"footer\",\"mobileLink\"]]]]],[30,[36,0],null,[[\"text\",\"href\"],[[32,1,[\"footer\",\"desktopText\"]],[32,1,[\"footer\",\"desktopLink\"]]]]]]]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"transform-contentful\",\"if\",\"transform-contentful-media\",\"let\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/three-column/template.hbs"
    }
  });

  _exports.default = _default;
});