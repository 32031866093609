define("cms-frontend/modifiers/table-of-contents/did-active-item-update", ["exports", "@clark/cms-ui/modifiers/table-of-contents/did-active-item-update"], function (_exports, _didActiveItemUpdate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _didActiveItemUpdate.default;
    }
  });
});