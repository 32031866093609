define("cms-frontend/helpers/transform-contentful-image", ["exports", "cms-frontend/utils/serialization"], function (_exports, _serialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TransformContentfulImageHelper extends Ember.Helper {
    compute([model]) {
      return (0, _serialization.deserializeImageModel)(model);
    }

  }

  _exports.default = TransformContentfulImageHelper;
});