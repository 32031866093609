define("cms-frontend/components/wrapper-components/centered-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hXrDQKSo",
    "block": "{\"symbols\":[\"header\",\"imgSrc\",\"footer\",\"@module\"],\"statements\":[[6,[37,3],[[30,[36,1],[[32,4,[\"header\"]]],null],[30,[36,2],[[32,4,[\"imgSrc\"]]],null],[30,[36,1],[[32,4,[\"footerButton\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"centered-content\",[],[[\"@header\",\"@body\",\"@footer\"],[[30,[36,0],null,[[\"title\"],[[32,1,[\"heading\"]]]]],[30,[36,0],null,[[\"leftContent\",\"imgSrc\",\"rightContent\"],[[30,[36,0],null,[[\"title\",\"description\"],[[32,4,[\"leftContentTitle\"]],[32,4,[\"leftContentDescription\"]]]]],[32,2],[30,[36,0],null,[[\"title\",\"description\"],[[32,4,[\"rightContentTitle\"]],[32,4,[\"rightContentDescription\"]]]]]]]],[30,[36,0],null,[[\"mobile\",\"desktop\"],[[30,[36,0],null,[[\"text\",\"href\"],[[32,3,[\"mobileText\"]],[32,3,[\"mobileLink\"]]]]],[30,[36,0],null,[[\"text\",\"href\"],[[32,3,[\"desktopText\"]],[32,3,[\"desktopLink\"]]]]]]]]]],null],[2,\"\\n\"]],\"parameters\":[1,2,3]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"transform-contentful\",\"transform-contentful-media\",\"let\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/centered-content/template.hbs"
    }
  });

  _exports.default = _default;
});