define("cms-frontend/components/cookie-banner/tracking-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y2vESDt2",
    "block": "{\"symbols\":[\"&attrs\",\"@header\",\"@description\",\"@dataPrivacyUrl\",\"@isChecked\",\"@isDisabled\",\"@onClick\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[\"cookie-banner-tracking\"],[[\"from\"],[\"cms-frontend/components/cookie-banner/tracking-option/styles\"]]]]]],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"inner\"],[[\"from\"],[\"cms-frontend/components/cookie-banner/tracking-option/styles\"]]]]]],[12],[2,\"\\n    \"],[10,\"p\"],[15,0,[31,[[30,[36,0],[\"header\"],[[\"from\"],[\"cms-frontend/components/cookie-banner/tracking-option/styles\"]]]]]],[14,\"data-test-tracking-header\",\"\"],[12],[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[15,0,[31,[[30,[36,0],[\"description\"],[[\"from\"],[\"cms-frontend/components/cookie-banner/tracking-option/styles\"]]]]]],[14,\"data-test-tracking-description\",\"\"],[12],[2,\"\\n      \"],[1,[32,3]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[15,0,[31,[[30,[36,0],[\"data-privacy-link\"],[[\"from\"],[\"cms-frontend/components/cookie-banner/tracking-option/styles\"]]]]]],[14,\"data-test-tracking-data-privacy-link\",\"\"],[12],[2,\"\\n      \"],[10,\"a\"],[14,\"rel\",\"noopener noreferrer\"],[14,\"target\",\"_blank\"],[15,6,[31,[[32,4]]]],[12],[2,\"Mehr Informationen\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"form-builder/input-field/toggle-button\",[],[[\"@isChecked\",\"@isDisabled\",\"@onClick\"],[[32,5],[32,6],[32,7]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/cookie-banner/tracking-option/template.hbs"
    }
  });

  _exports.default = _default;
});