define("cms-frontend/components/wrapper-components/sovendus/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/tEpaz/m",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,1],[\"wrapper\"],[[\"from\"],[\"cms-frontend/components/wrapper-components/sovendus/styles\"]]]]]],[14,1,\"sovendus-container\"],[14,\"data-test-sovendus\",\"\"],[12],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"fastboot\",\"isFastBoot\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,0,[\"injectScript\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"local-class\",\"unless\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/sovendus/template.hbs"
    }
  });

  _exports.default = _default;
});