define("cms-frontend/application/route", ["exports", "@sentry/browser", "cms-frontend/config/environment"], function (_exports, Sentry, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // Waiting for TS support: https://github.com/ember-intl/ember-intl/issues/733
  let ApplicationRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = (_temp = class ApplicationRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "api", _descriptor2, this);

      _initializerDefineProperty(this, "fastboot", _descriptor3, this);

      _initializerDefineProperty(this, "config", _descriptor4, this);
    }

    /*
      CAUTION: The CSRF token *has* to be loaded after at least one XHR has
      gone through, because in doing a request, we create a new session, but
      this session only takes effect *after* the request that created it has
      gone through. This means that whenever the token request is the first
      request, it would return a token that would be immediately invalidated,
      because a new session is created by requesting the token
    */
    async beforeModel(transition) {
      super.beforeModel(transition);

      try {
        this.intl.setLocale([this.config.getConfig('locale.languageTag')]);

        if (!this.fastboot.isFastBoot && !_environment.default.isPreviewMode) {
          /**
           * CAUTION: Do not try to further optimize this code by initiating the
           * token request after *any* API request has succeeded. While the token
           * is being loaded, there *must not* be any other API request in flight,
           * as this might create a new session and invalidate the token.
           */
          await this.fetchFeatures();
          await this.fetchCSRFToken();
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    }

    async fetchFeatures() {
      return this.api.get('features', {
        token: false
      });
    }

    async fetchCSRFToken() {
      return this.api.fetchCSRFToken();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "api", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "config", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ApplicationRoute;
});