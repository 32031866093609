define("cms-frontend/components/wrapper-components/lead-gen/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "cms-frontend/utils/constants", "cms-frontend/utils/forms/error-helpers", "cms-frontend/utils/serialization"], function (_exports, _component, _emberConcurrencyDecorators, _constants, _errorHelpers, _serialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LeadGenWrapperComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, (_class = (_temp = class LeadGenWrapperComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "api", _descriptor, this);

      _initializerDefineProperty(this, "tracking", _descriptor2, this);

      _initializerDefineProperty(this, "isSuccess", _descriptor3, this);

      _initializerDefineProperty(this, "highMarginFormError", _descriptor4, this);

      _initializerDefineProperty(this, "highMarginFormFieldErrors", _descriptor5, this);

      _initializerDefineProperty(this, "confirmedLeadFirstName", _descriptor6, this);
    }

    get formattedSuccessTitle() {
      return (this.args.module.successTitle || '').replace('{{firstName}}', this.confirmedLeadFirstName || '');
    }

    get formattedSuccessDescription() {
      const transformed = (0, _serialization.transformContentfulRichText)(this.args.module.successDescription);
      if (!transformed) return;
      const [, categoryName] = this.args.module.category.split('#');
      return transformed.replace('{{insuranceCategoryName}}', categoryName || '');
    }

    *handleHighMarginFormSubmit(formData) {
      const [categoryId] = this.args.module.category.split('#');
      this.highMarginFormError = undefined;
      this.highMarginFormFieldErrors = undefined;

      try {
        yield this.api.post('leads/lead_with_opportunity', {
          // TODO: These case transforms should be performed automatically
          // once our API service has a capability of automatic serialization
          // like in the customer app
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          phone_number: formData.phoneNumber,
          category_ident: categoryId,
          source_data: {
            anonymous_lead: true,
            terms_accepted: true,
            // Note: These are implicitly accepted now
            adjust: {
              utm_medium: 'seo'
            }
          }
        });
        this.confirmedLeadFirstName = formData.firstName;
        this.isSuccess = true;
        this.tracking.trackWithGTM(_constants.TrackingEvent.SUBMIT_LEAD_GEN_FORM);
      } catch (error) {
        if (error.body) {
          this.highMarginFormError = error.body.error;
        } // Custom error message handling


        const errorBody = error.body || {};

        if (errorBody.error || errorBody.errors && errorBody.errors[0] && errorBody.errors[0].title) {
          this.highMarginFormError = errorBody.error || errorBody.errors[0].title;
        }

        if (errorBody.errors && errorBody.errors.api) {
          this.highMarginFormFieldErrors = (0, _errorHelpers.parseLegacyFieldErrors)(errorBody);
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "api", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tracking", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isSuccess", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "highMarginFormError", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "highMarginFormFieldErrors", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "confirmedLeadFirstName", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleHighMarginFormSubmit", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "handleHighMarginFormSubmit"), _class.prototype)), _class));
  _exports.default = LeadGenWrapperComponent;
});