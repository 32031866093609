define("cms-frontend/services/contentful", ["exports", "contentful", "fetch", "cms-frontend/config/environment"], function (_exports, _contentful, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    contentful: {
      accessToken,
      space,
      environment,
      host
    }
  } = _environment.default; // FIXME:: Move Contentful type declarations to a more appropriate file

  let ContentfulService = (_dec = Ember.inject.service, (_class = (_temp = class ContentfulService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "config", _descriptor, this);

      _defineProperty(this, "client", _contentful.default.createClient({
        space,
        accessToken,
        environment,
        host,
        adapter: async requestConfig => {
          const url = this.buildURL(`${requestConfig.baseURL}/${requestConfig.url}`, requestConfig.params, requestConfig.paramsSerializer);
          const data = await (0, _fetch.default)(url, {
            method: requestConfig.method.toUpperCase(),
            headers: requestConfig.headers
          }).then(response => {
            if (response.ok) {
              return response.json();
            }

            throw response;
          });
          return {
            data
          };
        }
      }));
    }

    get layoutId() {
      return this.config.getConfig('layoutId');
    }

    /**
     * Code copied from below url for building url with params and serializing it.
     * https://github.com/axios/axios/blob/92d231387fe2092f8736bc1746d4caa766b675f5/lib/helpers/buildURL.js
     * @param {string} url
     * @param {Object} params
     * @param {function} paramsSerializer
     */
    buildURL(url, parameters, parametersSerializer) {
      const serializedParameters = parametersSerializer(parameters);
      let returnUrl = url;

      if (serializedParameters) {
        const hashmarkIndex = url.indexOf('#');

        if (hashmarkIndex !== -1) {
          returnUrl = url.slice(0, hashmarkIndex);
        }

        returnUrl += (!returnUrl.includes('?') ? '?' : '&') + serializedParameters;
      }

      return returnUrl;
    }

    getEntryById(id, query = {
      include: 5
    }) {
      return this.client.getEntry(id, query);
    }

    getEntriesByQuery(query) {
      return this.client.getEntries(query);
    }

    getEntriesBySlug(slug, layoutId) {
      return this.client.getEntries({
        include: 5,
        'fields.slug': slug,
        content_type: layoutId
      });
    }

    async getEntryBySlug(slug) {
      const entries = await this.client.getEntries({
        content_type: this.layoutId,
        'fields.slug': slug,
        include: 5
      });

      if (!entries || !entries.items || entries.items.length === 0) {
        return;
      }

      return entries.items[0];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ContentfulService;
});