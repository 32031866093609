define("cms-frontend/components/wrapper-components/textbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Erhtv+c5",
    "block": "{\"symbols\":[\"header\",\"items\",\"footer\",\"@module\"],\"statements\":[[6,[37,3],[[30,[36,1],[[32,4,[\"header\"]]],null],[30,[36,1],[[32,4,[\"items\"]]],null],[30,[36,2],[[32,4,[\"footer\"]],[30,[36,1],[[32,4,[\"footer\"]]],null],null],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"textbox\",[],[[\"@header\",\"@items\",\"@footer\"],[[30,[36,0],null,[[\"title\"],[[32,1,[\"heading\"]]]]],[32,2],[30,[36,0],null,[[\"mobile\",\"desktop\"],[[30,[36,0],null,[[\"text\",\"href\"],[[32,3,[\"mobileText\"]],[32,3,[\"mobileLink\"]]]]],[30,[36,0],null,[[\"text\",\"href\"],[[32,3,[\"desktopText\"]],[32,3,[\"desktopLink\"]]]]]]]]]],null],[2,\"\\n\"]],\"parameters\":[1,2,3]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"transform-contentful\",\"if\",\"let\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/textbox/template.hbs"
    }
  });

  _exports.default = _default;
});