define("cms-frontend/components/wrapper-components/youtube-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zeHu9MJe",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[8,\"youtube-content\",[],[[\"@videoId\",\"@headline\",\"@subtext\",\"@fallbackImage\",\"@button\"],[[32,1,[\"videoId\"]],[32,1,[\"headline\"]],[32,1,[\"subtext\"]],[30,[36,0],[[32,1,[\"fallbackImage\"]]],null],[30,[36,1],[[32,1,[\"button\"]]],null]]],null]],\"hasEval\":false,\"upvars\":[\"transform-contentful-media\",\"transform-contentful\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/youtube-content/template.hbs"
    }
  });

  _exports.default = _default;
});