define("cms-frontend/components/preview/error-screen/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "c-title": "rgba(32, 49, 81, 1)",
    "c-copy": "rgba(32, 49, 81, 0.7)",
    "error-screen": "_error-screen_c6wzbm",
    "layout-container": "_layout-container_c6wzbm",
    "message": "_message_c6wzbm",
    "hint": "_hint_c6wzbm"
  };
  _exports.default = _default;
});