define("cms-frontend/utils/url-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.extractSlugFromPath = extractSlugFromPath;
  _exports.extractShoeboxFromSlug = extractShoeboxFromSlug;

  function extractSlugFromPath(path) {
    if (!path) return path;
    const [pathname] = path.split('?');
    const slug = pathname.replace(/^\/+|\/+$/g, '');
    return slug;
  }

  function extractShoeboxFromSlug(slug) {
    if (slug === '/') {
      return 'home';
    }

    return slug.replace(/\//g, '-');
  }
});