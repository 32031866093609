define("cms-frontend/helpers/transform-rich-text-header", ["exports", "@clark/cms-ui/helpers/transform-rich-text-header"], function (_exports, _transformRichTextHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _transformRichTextHeader.default;
    }
  });
});