define("cms-frontend/components/preview/entry-preview-screen/component", ["exports", "@glimmer/component", "cms-frontend/utils/serialization"], function (_exports, _component, _serialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EntryPreviewScreenComponent extends _component.default {
    get entry() {
      if (!this.args.entry) return undefined;
      return (0, _serialization.deserializeModel)(this.args.entry);
    }

    get modules() {
      const {
        layoutComponents = []
      } = this.entry;
      return layoutComponents.map(_serialization.deserializeModel);
    }

    get modals() {
      const {
        modals = []
      } = this.entry;
      return modals.map(_serialization.deserializeModel);
    }

  }

  _exports.default = EntryPreviewScreenComponent;
});