define("cms-frontend/components/lead-gen-cta/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lead-gen": "_lead-gen_ekjqvs"
  };
  _exports.default = _default;
});