define("cms-frontend/components/preview/not-found-screen/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PBQ4RIaQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"not-found-screen\"],[[\"from\"],[\"cms-frontend/components/preview/not-found-screen/styles\"]]]]]],[14,\"data-test-not-found-screen\",\"\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"layout-container\"],[[\"from\"],[\"cms-frontend/components/preview/not-found-screen/styles\"]]]]]],[12],[2,\"\\n    \"],[10,\"h3\"],[15,0,[31,[[30,[36,0],[\"message\"],[[\"from\"],[\"cms-frontend/components/preview/not-found-screen/styles\"]]]]]],[14,\"data-test-not-found-screen-message\",\"\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"preview.not-found-screen.message\"],null]],[2,\" 🤷🏻‍♂️\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"t\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/preview/not-found-screen/template.hbs"
    }
  });

  _exports.default = _default;
});