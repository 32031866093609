define("cms-frontend/helpers/get-toc-links", ["exports", "@contentful/rich-text-types", "cms-frontend/utils/rich-text-header"], function (_exports, _richTextTypes, _richTextHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (parameters) {
    const [content] = parameters;
    if (!content) return [];
    const h2Nodes = content.filter(node => node.nodeType.includes(_richTextTypes.BLOCKS.HEADING_2));
    return h2Nodes.map(node => {
      const text = node.content.reduce((totalContent, currentNode) => {
        return totalContent + currentNode.value;
      }, '');
      const id = (0, _richTextHeader.default)(node);
      return {
        id,
        url: `#${id}`,
        text
      };
    }).filter(node => Ember.isPresent(node.text));
  });

  _exports.default = _default;
});