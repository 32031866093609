define("cms-frontend/utils/serialization", ["exports", "@contentful/rich-text-html-renderer", "@contentful/rich-text-types", "cms-frontend/components/wrapper-components/rich-text/styles", "cms-frontend/utils/rich-text-header"], function (_exports, _richTextHtmlRenderer, _richTextTypes, _styles, _richTextHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deserializeModel = deserializeModel;
  _exports.deserializeImageModel = deserializeImageModel;
  _exports.deepDeserializeModel = deepDeserializeModel;
  _exports.transformContentfulRichText = transformContentfulRichText;

  function deserializeModel(model) {
    (false && !(Boolean(model)) && Ember.assert('deserializeModel: Missing argument model', Boolean(model)));
    (false && !(Boolean(model.sys && model.fields)) && Ember.assert('deserializeModel: model is not a valid Contentful Entry (should have `sys` and `fields` props)', Boolean(model.sys && model.fields)));
    return model.fields;
  }

  function deserializeImageModel(model) {
    if (!model) return;
    const fields = deserializeModel(model);
    return {
      url: fields.file.url,
      // Ideally alt text should be based on the context of the image. Since we do
      // not have an option to force this in Contentful's Media model right now,
      // we are adding a default text here. Content creators should provide a
      // meaningful alternate text to every image that they upload.
      altText: fields.description || 'Image'
    };
  }

  function isObjectModel(model) {
    return typeof model === 'object' && model !== null;
  }

  function deepDeserializeModel(model) {
    if (!model) return;

    if (Array.isArray(model)) {
      return model.map(entry => deepDeserializeModel(entry));
    }

    if (!isObjectModel(model) || !(model.sys && model.fields)) {
      return model;
    }

    if (model.sys.type === 'Asset') {
      return deserializeImageModel(model);
    }

    const deserializedModel = {};
    Object.entries(model.fields).forEach(([key, value]) => {
      deserializedModel[key] = deepDeserializeModel(value);
    });
    return deserializedModel;
  }

  function transformContentfulRichText(node) {
    if (!node) return;
    const options = {
      renderNode: {
        [_richTextTypes.BLOCKS.HEADING_2]: (headerNode, next) => {
          return `<h2 id='${(0, _richTextHeader.default)(headerNode)}' class="${_styles.default['rich-text-header-anchor']}" >${next(headerNode.content)}</h2>`;
        },
        [_richTextTypes.BLOCKS.UL_LIST]: (unorderedListNode, next) => {
          return `<ul class=${_styles.default['rich-text-unordered-list']}>${next(unorderedListNode.content)}</ul>`;
        },
        [_richTextTypes.BLOCKS.PARAGRAPH]: (paragraphNode, next) => {
          const style = paragraphNode.content[0].value === '' ? `class=${_styles.default['empty-paragraph']}` : '';
          return `<p ${style}>${next(paragraphNode.content)}</p>`;
        },
        [_richTextTypes.BLOCKS.HR]: () => {
          return `<hr class='${_styles.default['line-breaker']}'/>`;
        }
      }
    };

    if (node.nodeType !== 'document') {
      return (0, _richTextHtmlRenderer.documentToHtmlString)({
        data: {},
        content: [node],
        nodeType: _richTextTypes.BLOCKS.DOCUMENT
      }, options);
    }

    return (0, _richTextHtmlRenderer.documentToHtmlString)(node, options);
  }
});