define("cms-frontend/helpers/kebab-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (parameters) {
    const [camelCase] = parameters;
    if (!camelCase) return '';
    return camelCase.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
  });

  _exports.default = _default;
});