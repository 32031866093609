define("cms-frontend/helpers/transform-contentful", ["exports", "cms-frontend/utils/serialization"], function (_exports, _serialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TransformContentfulHelper extends Ember.Helper {
    compute([model]) {
      if (!model) return model;

      if (Array.isArray(model)) {
        return model.map(entry => (0, _serialization.deserializeModel)(entry));
      }

      return (0, _serialization.deserializeModel)(model);
    }

  }

  _exports.default = TransformContentfulHelper;
});