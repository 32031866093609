define("cms-frontend/404/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "page-404-container": "_page-404-container_1fq743",
    "page-404-image": "_page-404-image_1fq743",
    "page-404-title": "_page-404-title_1fq743"
  };
  _exports.default = _default;
});