define("cms-frontend/modifiers/dropdown-menu", ["exports", "@clark/cms-ui/modifiers/dropdown-menu"], function (_exports, _dropdownMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dropdownMenu.default;
    }
  });
});