define("cms-frontend/components/wrapper-components/accordion/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "dark-blue": "#0439D7",
    "c-white": "#FFFFFF",
    "background-light-gray": "#F5F6FA",
    "up-to-medium": "only screen and (max-width: calc(769px - 1px))",
    "from-medium": "only screen and (min-width: 769px)",
    "accordion-item-color": "#333",
    "accordion": "_accordion_1me62s",
    "accordion-title": "_accordion-title_1me62s",
    "accordion-item": "_accordion-item_1me62s",
    "accordion-item_label": "_accordion-item_label_1me62s",
    "accordion-item_content": "_accordion-item_content_1me62s",
    "accordion-item_content_wrapper": "_accordion-item_content_wrapper_1me62s"
  };
  _exports.default = _default;
});