define("cms-frontend/components/preview/entry-preview-screen/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "from-desktop": "only screen and (min-width: 1200px)",
    "entry-preview-screen": "_entry-preview-screen_sveont",
    "layout-container": "_layout-container_sveont",
    "is-individual-component-preview": "_is-individual-component-preview_sveont"
  };
  _exports.default = _default;
});