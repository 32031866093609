define("cms-frontend/components/wrapper-components/faq/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/lxnKQSx",
    "block": "{\"symbols\":[\"header\",\"items\",\"@module\"],\"statements\":[[6,[37,2],[[30,[36,1],[[32,3,[\"header\"]]],null],[30,[36,1],[[32,3,[\"items\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"faq\",[],[[\"@header\",\"@items\"],[[30,[36,0],null,[[\"title\"],[[32,1,[\"heading\"]]]]],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"transform-contentful\",\"let\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/faq/template.hbs"
    }
  });

  _exports.default = _default;
});