define("cms-frontend/services/config", ["exports", "@clark/ember-brand-config"], function (_exports, _emberBrandConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ConfigService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "currentConfig", _emberBrandConfig.default);
    }

    getConfig(key) {
      return Ember.get(Ember.get(this, 'currentConfig'), key);
    }

    getConfigWithDefault(key, defaultValue) {
      return Ember.getWithDefault(Ember.get(this, 'currentConfig'), key, defaultValue);
    }
    /**
     * This is a magic method that is only added for convenience.
     * It is invoked whenver you call `get(this, someProp)` where `someProp` is a
     * property key that is now known on this instance, i.e. pretty much anything.
     * The call will be delegated to `this.getConfig` so that you can easily use
     * this service with computed property macros like `reads`.
     */


    unknownProperty(key) {
      return this.getConfig(key);
    }
    /**
     * Mocks the config for tests. Only the parameters required for the test need
     * to be provided and will be available.
     */


    mockConfig(currentConfig) {
      Ember.set(this, 'currentConfig', {
        brand: 'clark',
        locale: {
          languageTag: 'de-de'
        },
        ...currentConfig
      });
    }

    restoreOriginalConfig() {
      Ember.set(this, 'currentConfig', _emberBrandConfig.default);
    }

  }

  _exports.default = ConfigService;
});