define("cms-frontend/modifiers/auto-focus", ["exports", "@zestia/ember-auto-focus/modifiers/auto-focus"], function (_exports, _autoFocus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _autoFocus.default;
    }
  });
});