define("cms-frontend/components/cookie-banner/tracking-option/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cookie-banner-tracking": "_cookie-banner-tracking_11ktfz",
    "header": "_header_11ktfz",
    "description": "_description_11ktfz",
    "inner": "_inner_11ktfz"
  };
  _exports.default = _default;
});