define("cms-frontend/components/wrapper-components/trustpilot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GeoxxLHi",
    "block": "{\"symbols\":[\"model\",\"@module\"],\"statements\":[[6,[37,2],[[30,[36,0],null,[[\"header\",\"tag\"],[[30,[36,1],[[32,2,[\"header\"]]],null],[32,2,[\"tag\"]]]]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"trustpilot\",[],[[\"@header\",\"@tag\"],[[30,[36,0],null,[[\"title\",\"description\"],[[32,1,[\"header\",\"heading\"]],[32,1,[\"header\",\"description\"]]]]],[32,1,[\"tag\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"transform-contentful\",\"let\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/trustpilot/template.hbs"
    }
  });

  _exports.default = _default;
});