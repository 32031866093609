define("cms-frontend/styles/app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "cta-secondary-border": "#DADDEC",
    "from-medium": "only screen and (min-width: 769px)"
  };
  _exports.default = _default;
});