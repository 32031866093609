define("cms-frontend/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.mediaQueries = _exports.breakpoints = void 0;
  // FIXME: Extract to a separate package to avoid repetition:
  // https://clarkteam.atlassian.net/browse/JCLARK-60691
  // Breakpoints
  const breakpoints = {
    xsmall: 0,
    small: 400,
    medium: 769,
    large: 1200
  }; // Media queries
  // FIXME: Remove some repetition and iterate
  // over `breakpoints` object keys instead

  _exports.breakpoints = breakpoints;
  const mediaQueries = {
    beforeSmall: `only screen and (max-width: ${breakpoints.small - 0.02}px)`,
    fromSmall: `only screen and (min-width: ${breakpoints.small}px)`,
    smallOnly: `only screen and (min-width: ${breakpoints.small}px) and (max-width: ${breakpoints.medium - 0.02}px)`,
    beforeMedium: `only screen and (max-width: ${breakpoints.medium - 0.02}px)`,
    fromMedium: `only screen and (min-width: ${breakpoints.medium}px)`,
    mediumOnly: `only screen and (min-width: ${breakpoints.medium}px) and (max-width: ${breakpoints.large - 0.02}px)`,
    beforeLarge: `only screen and (max-width: ${breakpoints.large - 0.02}px)`,
    fromLarge: `only screen and (min-width: ${breakpoints.large}px)`,
    largeOnly: `only screen and (min-width: ${breakpoints.large}px)`,
    // Some aliases
    beforeDesktop: `only screen and (max-width: ${breakpoints.large - 0.02}px)`,
    desktop: `only screen and (min-width: ${breakpoints.large}px)`
  };
  _exports.mediaQueries = mediaQueries;
  var _default = mediaQueries;
  _exports.default = _default;
});