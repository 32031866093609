define("cms-frontend/helpers/transform-contentful-media", ["exports", "cms-frontend/utils/serialization"], function (_exports, _serialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TransformContentfulMediaHelper extends Ember.Helper {
    compute(parameters, hash) {
      const [model] = parameters;

      if (!model) {
        return;
      }

      if (Array.isArray(model)) {
        return model.map(i => {
          const imgAttributes = (0, _serialization.deserializeImageModel)(i[hash.prop]);
          return { ...i,
            [hash.prop]: imgAttributes
          };
        });
      }

      if (hash.prop) {
        const imgAttributes = (0, _serialization.deserializeImageModel)(model[hash.prop]);
        return { ...model,
          [hash.prop]: imgAttributes
        };
      }

      return (0, _serialization.deserializeImageModel)(model);
    }

  }

  _exports.default = TransformContentfulMediaHelper;
});