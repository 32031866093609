define("cms-frontend/preview/entry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/gnEccAl",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[6,[37,0],[[32,1,[\"entry\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"preview/entry-preview-screen\",[],[[\"@entryId\",\"@entry\"],[[32,1,[\"entryId\"]],[32,1,[\"entry\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"preview/error-screen\",[],[[\"@entryId\"],[[32,1,[\"entryId\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "cms-frontend/preview/entry/template.hbs"
    }
  });

  _exports.default = _default;
});