define("cms-frontend/preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wpQaJ9fn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,\"data-test-preview-route\",\"\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "cms-frontend/preview/template.hbs"
    }
  });

  _exports.default = _default;
});