define("cms-frontend/components/wrapper-components/carousel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xx/5K82S",
    "block": "{\"symbols\":[\"header\",\"items\",\"@module\"],\"statements\":[[6,[37,3],[[30,[36,1],[[32,3,[\"header\"]]],null],[30,[36,2],[[30,[36,1],[[32,3,[\"items\"]]],null]],[[\"prop\"],[\"imgSrc\"]]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"carousel\",[],[[\"@header\",\"@items\"],[[30,[36,0],null,[[\"title\",\"description\"],[[32,1,[\"heading\"]],[32,1,[\"description\"]]]]],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"transform-contentful\",\"transform-contentful-media\",\"let\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/carousel/template.hbs"
    }
  });

  _exports.default = _default;
});