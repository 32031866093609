define("cms-frontend/404/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UTPTsKPr",
    "block": "{\"symbols\":[],\"statements\":[[8,\"cms-header\",[],[[\"@navigation\"],[[32,0,[\"model\",\"menuItem\"]]]],null],[2,\"\\n\"],[10,\"section\"],[15,0,[31,[[30,[36,1],[\"container\"],null],\" \",[30,[36,0],[\"page-404-container\"],[[\"from\"],[\"cms-frontend/404/styles\"]]]]]],[12],[2,\"\\n  \"],[8,\"icon\",[[16,0,[31,[[30,[36,0],[\"page-404-image\"],[[\"from\"],[\"cms-frontend/404/styles\"]]]]]]],[[\"@source\"],[\"404\"]],null],[2,\"\\n  \"],[10,\"h2\"],[15,0,[31,[[30,[36,2],[\"h2\"],null],\" \",[30,[36,0],[\"page-404-title\"],[[\"from\"],[\"cms-frontend/404/styles\"]]]]]],[12],[2,\"\\n    \"],[1,[30,[36,4],[[30,[36,3],[\"404.title\"],null]],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"button\",[],[[\"@url\"],[\"/\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,3],[\"404.cta\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"cms-footer\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"grid\",\"typo\",\"t\",\"markdown-to-html\"]}",
    "meta": {
      "moduleName": "cms-frontend/404/template.hbs"
    }
  });

  _exports.default = _default;
});