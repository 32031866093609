define("cms-frontend/components/wrapper-components/trust-seals/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3m/9ZUXO",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[8,\"trust-seal-section\",[],[[\"@items\"],[[30,[36,0],[[32,1,[\"items\"]]],null]]],null]],\"hasEval\":false,\"upvars\":[\"deep-transform-contentful\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/trust-seals/template.hbs"
    }
  });

  _exports.default = _default;
});