define("cms-frontend/components/wrapper-components/switch-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y+eadWxk",
    "block": "{\"symbols\":[\"@module\"],\"statements\":[[8,\"button\",[],[[\"@appearance\",\"@url\"],[[30,[36,1],[[30,[36,0],[[32,1,[\"isSecondary\"]]],null],\"primary\",\"secondary\"],null],[30,[36,1],[[30,[36,2],[\"any\"],null],[32,1,[\"mobileLink\"]],[32,1,[\"desktopLink\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,1],[[30,[36,2],[\"any\"],null],[32,1,[\"mobileText\"]],[32,1,[\"desktopText\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"if\",\"is-mobile\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/switch-button/template.hbs"
    }
  });

  _exports.default = _default;
});