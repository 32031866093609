define("cms-frontend/components/wrapper-components/crosslink/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qAJ1K7GW",
    "block": "{\"symbols\":[\"model\",\"@module\"],\"statements\":[[6,[37,4],[[30,[36,3],null,[[\"header\",\"items\"],[[30,[36,2],[[32,2,[\"header\"]]],null],[30,[36,1],[[32,2,[\"items\"]]],null]]]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"crosslink/section\",[],[[\"@title\",\"@items\"],[[30,[36,0],[[32,1,[\"header\",\"heading\"]]],null],[32,1,[\"items\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"transform-rich-text-header\",\"deep-transform-contentful\",\"transform-contentful\",\"hash\",\"let\"]}",
    "meta": {
      "moduleName": "cms-frontend/components/wrapper-components/crosslink/template.hbs"
    }
  });

  _exports.default = _default;
});